import React from 'react';
import { Col, Input, Row } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { RightOutlined } from '@ant-design/icons';
import footer from 'src/assets/img/footer.png';

import styles from './styles.module.scss';
import { FaxIcon, HeadPhoneIcon, PhoneIcon, PinIcon } from 'src/assets/icons';

export const AppFooter = () => {
  return (
    <>
      <Footer className={styles.app_footer}>
        <Row className={styles.wrapHeader}>
          <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <div className={styles.content}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={7} xl={7} className={styles.col}>
                  <div className={styles.icon}>
                    <PinIcon />
                    <span className={styles.small_text}>
                      Tổng công ty Viễn thông MobiFone <br /> Số 01 phố Phạm Văn Bạch, Yên Hòa, Cầu Giấy, Hà Nội
                    </span>
                  </div>
                  <div className={styles.icon}>
                    <HeadPhoneIcon />
                    <span className={styles.small_text}>(+84-24) 3783 1800</span>
                  </div>
                  <div className={styles.icon}>
                    <FaxIcon />
                    <span className={styles.small_text}>(+84-24) 3783 1734</span>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} className={styles.col}>
                  <div className={styles.icon}>
                    <PhoneIcon />
                    <span className={styles.small_text}>Tổng đài</span>
                  </div>
                  <div className={styles.small_text}>
                    <span style={{ color: '#2692FF', marginLeft: '20px' }}>9090</span>(200đ/phút)
                  </div>
                  <div className={styles.small_text}>
                    <span style={{ color: '#2692FF', marginLeft: '20px' }}>18001090</span> (Miễn phí)
                  </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={7} xl={7} className={styles.col}>
                  <div className={styles.small_text}>
                    Giấy chứng nhận đăng ký doanh nghiệp: Mã số doanh nghiệp: 0100686209. Đăng ký thay đổi lần thứ 10
                    ngày 10/03/2021, cấp bởi sở KHĐT Thành phố Hà Nội
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} className={styles.col}>
                  <img src={footer} alt="footer" className={styles.footerImg} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        </Row>
      </Footer>
      <Footer className={styles.copyright}>
        <span className={styles.small_text}>© Copyright 2023 - MobiFone</span>
      </Footer>
    </>
  );
};
