export const AppRoutes = {
  home: '/',
  // homes: (id: any, sdt: any) => `/${id}/${sdt}`,
  homeWithIsdn: '/home-with-isdn',
  login: '/login',
  page404: '*',
  page403: '/403',
  success:'/thanh-cong/:isdn',
  successes:( isdn: any) =>`/thanh-cong/${isdn}`
};
