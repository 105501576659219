import React from 'react';
import { AppRoutes } from '../helpers/app.routes';

//Truy cập qua 4g
const WithIsdnHomePage = React.lazy(async () => await import('src/pages/home/containers/homeWithIsdn'));

// Thành công
const SuccessPage = React.lazy(async () => await import('src/pages/success'));

export const routers = [
  // Thành công
  {
    path: AppRoutes.success,
    element: <SuccessPage />,
    permission: [],
  },
  // Truy cập qua 4g
  {
    path: AppRoutes.homeWithIsdn,
    element: <WithIsdnHomePage />,
    permission: [],
  },
];
