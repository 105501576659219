import React from 'react';
import styles from './styles.module.scss';
import logo from 'src/assets/img/logo.png';
import { Col, Row } from 'antd';

export const Header: React.FC = () => {
  return (
    <Row className={styles.wrapHeader}>
      <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <img src={logo} alt="logo" className={styles.logo} />
      </Col>
      <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
    </Row>
  );
};
