import { Layout } from 'antd';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { Header } from '../Header';
import { AppFooter } from '../Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = () => {
  return (
    <Layout>
      <div className={classNames(styles.section)}>
        <ToastContainer />
        <Header />
        <div className={styles.content}>
          <Outlet />
        </div>
        <AppFooter />
      </div>
    </Layout>
  );
};

export default MainLayout;
