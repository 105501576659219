import React, { Suspense, memo } from 'react';
import './styles/app.scss';
import { RootRouter } from './routes';

const App = memo(() => {
  return (
    <Suspense>
      <RootRouter />
    </Suspense>
  );
});

App.displayName = 'MyApp';

export default App;
