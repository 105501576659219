import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from '../helpers/app.routes';
import MainLayout from '../layouts/Main';

import { Page403 } from '../pages/ErrorPage/403';
import { Page404 } from '../pages/ErrorPage/404';
import { routers } from './constants';
const HomePage = React.lazy(async () => await import('src/pages/home/containers/home'));

export const RootRouter = React.memo(() => {
  RootRouter.displayName = 'RootRouter';
  return (
    <Routes>
      <Route path={AppRoutes.home} element={<MainLayout />}>
        <Route index element={<HomePage />} />
        {routers.map((route: any, index: any) => {
          return <Route key={`route${index}`} path={route.path} element={route.element} />;
        })}
      </Route>
      <Route path={AppRoutes.page404} element={<Page404 />} />
      <Route path={AppRoutes.page403} element={<Page403 />} />
    </Routes>
  );
});
